import React, { useState, useEffect } from 'react';
import { Modal, Button, Container, Row, Col } from 'react-bootstrap';
import QuillEditor from './QuillEditor';
import ImageUploader from './ImageUploader';

const CourseCreator = ({ courseId }) => {
  const [courseElements, setCourseElements] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [currentElement, setCurrentElement] = useState(null);
  const [editIndex, setEditIndex] = useState(null);

  const handleCloseModal = () => {
    setShowModal(false);
    setCurrentElement(null);
    setEditIndex(null);
  };

  const handleShowModal = (type, index = null) => {
    if (index !== null) {
      // Editing existing element
      setCurrentElement({ ...courseElements[index] });
    } else {
      // Adding new element
      setCurrentElement({ type, content: '' });
    }
    setEditIndex(index);
    setShowModal(true);
  };

  const handleSaveElement = () => {
    if (editIndex !== null) {
      // Editing existing element
      setCourseElements(prevElements => {
        const newElements = [...prevElements];
        newElements[editIndex] = currentElement;
        return newElements;
      });
    } else {
      // Adding new element
      setCourseElements(prevElements => [...prevElements, currentElement]);
    }
    handleCloseModal();
  };

  const renderElement = (element, index) => {
    return (
      <Row key={index} className="mb-3">
        <Col md={9}>
          {element.type === 'text' ? (
            <div dangerouslySetInnerHTML={{ __html: element.content }} />
          ) : (
            <img src={element.content} alt={`Course element ${index + 1}`} style={{ maxWidth: '100%' }} />
          )}
        </Col>
        <Col md={3}>
          <Button onClick={() => handleShowModal(element.type, index)}>Edytuj</Button>
        </Col>
      </Row>
    );
  };

  return (
    <Container>
      <h1 className="my-4">Tworzenie kursu</h1>
      {courseElements.map(renderElement)}
      <Row className="mt-4">
        <Col>
          <Button onClick={() => handleShowModal('text')}>Dodaj tekst</Button>
        </Col>
        <Col>
          <Button onClick={() => handleShowModal('image')}>Dodaj obraz</Button>
        </Col>
      </Row>

      <Modal show={showModal} onHide={handleCloseModal} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>{editIndex !== null ? 'Edytuj element' : 'Dodaj nowy element'}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {currentElement && currentElement.type === 'text' ? (
            <QuillEditor
              value={currentElement.content}
              onChange={(content) => setCurrentElement({ ...currentElement, content })}
            />
          ) : (
            <ImageUploader
              value={currentElement?.content}
              onChange={(imageUrl) => setCurrentElement({ ...currentElement, content: imageUrl })}
              courseId={courseId}
            />
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Anuluj
          </Button>
          <Button variant="primary" onClick={handleSaveElement}>
            Zapisz
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
};

export default CourseCreator;
