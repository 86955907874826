import React from 'react';

function PublicPage() {
  return (
    <div>
      <h2>Public Page</h2>
      <p>This is a public page that anyone can access.</p>
    </div>
  );
}

export default PublicPage;
