import React, { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import axios from 'axios';
import { ProgressBar } from 'react-bootstrap';

const ImageUploader = ({ value, onChange, courseId }) => {
  const [uploadProgress, setUploadProgress] = useState(0);
  const [previewUrl, setPreviewUrl] = useState(null);

  const onDrop = useCallback(async (acceptedFiles) => {
    const file = acceptedFiles[0];
    setPreviewUrl(URL.createObjectURL(file));
    const formData = new FormData();
    formData.append('image', file);

    try {
      const response = await axios.post(`/api/courses/upload-image`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
        onUploadProgress: (progressEvent) => {
          const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          setUploadProgress(percentCompleted);
        },
      });
      onChange(response.data.imageUrl);
      setUploadProgress(0);
    } catch (error) {
      console.error('Error uploading image:', error);
      setUploadProgress(0);
    }
  }, [onChange, courseId]);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: 'image/*',
    multiple: false
  });

  return (
    <div>
      <div {...getRootProps()} style={dropzoneStyles(isDragActive)}>
        <input {...getInputProps()} />
        {
          isDragActive ?
            <p>Upuść obraz tutaj ...</p> :
            <p>Przeciągnij i upuść obraz tutaj, lub kliknij aby wybrać plik</p>
        }
      </div>
      {uploadProgress > 0 && <ProgressBar now={uploadProgress} label={`${uploadProgress}%`} className="mt-2" />}
      {(previewUrl || value) && (
        <div className="mt-3">
          <img src={previewUrl || value} alt="Preview" style={{ maxWidth: '100%', maxHeight: '200px' }} />
        </div>
      )}
    </div>
  );
};

const dropzoneStyles = (isDragActive) => ({
  border: `2px dashed ${isDragActive ? '#2196f3' : '#cccccc'}`,
  borderRadius: '4px',
  padding: '20px',
  textAlign: 'center',
  cursor: 'pointer',
  background: isDragActive ? '#e6f7ff' : '#fafafa',
  color: isDragActive ? '#2196f3' : '#bdbdbd',
  outline: 'none',
  transition: 'border .24s ease-in-out'
});

export default ImageUploader;
