import React from 'react';
import { useAuth } from '../contexts/AuthContext';
import CourseCreator from './CourseCreator';

function ProtectedPage() {
  const { currentUser } = useAuth();

  return (
    <div>
      <h2>Protected Page</h2>
      <p>Welcome, {currentUser.name}! This is a protected page.</p>
      <CourseCreator courseId="123" />
    </div>
  );
}

export default ProtectedPage;
