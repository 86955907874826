import React, { useEffect, useRef, useCallback } from 'react';
import ReactQuill from 'react-quill';
import Quill from 'quill';
import 'react-quill/dist/quill.snow.css';

// const Delta = Quill.import('delta');

const QuillEditor = ({ value, onChange, rows = 40 }) => {
  const quillRef = useRef(null);

  const clearFormatting = useCallback(() => {
    const quill = quillRef.current.getEditor();
    const range = quill.getSelection();
    if (range) {
      if (range.length > 0) {
        quill.removeFormat(range.index, range.length);
      } else {
        quill.removeFormat(0, quill.getLength());
      }
    }
  }, []);

  useEffect(() => {
    if (quillRef.current) {
      const quill = quillRef.current.getEditor();
      quill.getModule('toolbar').addHandler('clear', clearFormatting);
    }
  }, [clearFormatting]);

  const modules = {
    toolbar: {
      container: [
        [{ 'header': [1, 2, 3, false] }],
        ['bold', 'italic', 'underline', 'strike'],
        [{'list': 'ordered'}, {'list': 'bullet'}],
        [{ 'direction': 'rtl' }],
        [{ 'align': [] }],
        ['link', 'image'],
        ['clean'],
        ['clear']  // Nowy przycisk do czyszczenia formatowania
      ],
      handlers: {
        clear: clearFormatting
      }
    },
    clipboard: {
      matchVisual: false,
    },
  };

  const formats = [
    'header',
    'bold', 'italic', 'underline', 'strike',
    'list', 'bullet',
    'direction',
    'align',
    'link', 'image'
  ];

  const editorHeight = rows * 10;
  const toolbarHeight = 42;

  return (
    <div className="mb-3" >
	  {/*<div style={{ height: `${editorHeight + toolbarHeight}px` }}>*/}
      <ReactQuill
        ref={quillRef}
        theme="snow"
        value={value}
        onChange={onChange}
        modules={modules}
        formats={formats}
        style={{ height: '100%' }}
      />
    </div>
  );
};

export default QuillEditor;
