import React, { useState, useEffect, useCallback } from 'react';
import { Container, Button, Form, Alert } from 'react-bootstrap';
import CourseElementModal from './CourseElementModal';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';

const CourseEditor = () => {
  const [courseTitle, setCourseTitle] = useState('');
  const [courseElements, setCourseElements] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [modalType, setModalType] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const { id } = useParams();
  const navigate = useNavigate();

  const fetchCourse = useCallback(async () => {
    if (!id) return;
    try {
      const response = await axios.get(`/api/courses/${id}`);
      setCourseTitle(response.data.title);
      setCourseElements(response.data.elements);
    } catch (error) {
      console.error('Error fetching course:', error);
      setError('Failed to load course. Please try again.');
    }
  }, [id]);

  useEffect(() => {
    fetchCourse();
  }, [fetchCourse]);

  const handleAddElement = async (type, content) => {
    const newElement = { type, content };
    const updatedElements = [...courseElements, newElement];
    await saveCourse(updatedElements);
  };

  const saveCourse = async (elements) => {
    const courseData = {
      title: courseTitle,
      elements: elements
    };

    try {
      if (id) {
        await axios.put(`/api/courses/${id}`, courseData);
        setSuccess('Course updated successfully!');
      } else {
        const response = await axios.post('/api/courses', courseData);
        navigate(`/courses/edit/${response.data.id}`);
        setSuccess('Course created successfully!');
      }
      await fetchCourse();
    } catch (error) {
      console.error('Error saving course:', error);
      setError('Failed to save course. Please try again.');
    }
    setShowModal(false);
  };

  const handleTitleChange = (e) => {
    setCourseTitle(e.target.value);
  };

  const handleTitleBlur = () => {
    if (id) {
      saveCourse(courseElements);
    }
  };

  const handleDeleteElement = async (index) => {
    const updatedElements = courseElements.filter((_, i) => i !== index);
    await saveCourse(updatedElements);
  };

  const HTMLElement = ({ content }) => {
    const [html, setHtml] = useState('');

    useEffect(() => {
      const fetchHTML = async () => {
        try {
          const response = await axios.get(`/api/courses/${id}/${content}`);
          setHtml(response.data);
        } catch (error) {
          console.error('Error fetching HTML content:', error);
          setHtml('<p>Error loading content.</p>');
        }
      };

      fetchHTML();
    }, [content]);

    return <div dangerouslySetInnerHTML={{ __html: html }} />;
  };

  const renderElement = (element, index) => {
    switch (element.type) {
      case 'html':
        return (
          <div key={index} className="mb-3">
            <HTMLElement content={element.content} />
            <Button variant="danger" size="sm" onClick={() => handleDeleteElement(index)}>Delete</Button>
          </div>
        );
      case 'image':
        return (
          <div key={index} className="mb-3">
            <img src={`${element.content}`} alt={`Course element ${index}`} style={{ maxWidth: '100%' }} />
            <Button variant="danger" size="sm" onClick={() => handleDeleteElement(index)}>Delete</Button>
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <Container>
      <h1>{id ? 'Edit Course' : 'Create New Course'}</h1>
      {error && <Alert variant="danger">{error}</Alert>}
      {success && <Alert variant="success">{success}</Alert>}
      <Form.Group className="mb-3">
        <Form.Label>Course Title</Form.Label>
        <Form.Control
          type="text"
          value={courseTitle}
          onChange={handleTitleChange}
          onBlur={handleTitleBlur}
          placeholder="Enter course title"
        />
      </Form.Group>
      {courseElements.map((element, index) => renderElement(element, index))}
      <Button
        variant="primary"
        className="me-2 mb-3"
        onClick={() => { setModalType('html'); setShowModal(true); }}
      >
        Add HTML Element
      </Button>
      <Button
        variant="secondary"
        className="mb-3"
        onClick={() => { setModalType('image'); setShowModal(true); }}
      >
        Add Image Element
      </Button>

      <CourseElementModal
        show={showModal}
        onHide={() => setShowModal(false)}
        onSave={handleAddElement}
        type={modalType}
        courseId={id}
      />
    </Container>
  );
};

export default CourseEditor;
