import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { Container } from 'react-bootstrap';
import Navbar from './components/Navbar';
import LoginPage from './pages/LoginPage';
import PublicPage from './pages/PublicPage';
import ProtectedPage from './pages/ProtectedPage';
import CourseEditor from './pages/CourseEditor';
import CoursesList from './pages/CoursesList';
import { AuthProvider, useAuth } from './contexts/AuthContext';

function PrivateRoute({ children }) {
  const { currentUser } = useAuth();
  return currentUser ? children : <Navigate to="/login" replace />;
}

function PublicOnlyRoute({ children }) {
  const { currentUser } = useAuth();
  return currentUser ? <Navigate to="/" replace /> : children;
}

function App() {
  return (
    <AuthProvider>
      <Router>
        <Navbar />
        <Container className="mt-4">
          <Routes>
            <Route path="/" element={<PublicPage />} />
            <Route
              path="/login"
              element={
                <PublicOnlyRoute>
                  <LoginPage />
                </PublicOnlyRoute>
              }
            />
            <Route
              path="/protected"
              element={
                <PrivateRoute>
                  <ProtectedPage />
                </PrivateRoute>
              }
            />
            <Route
              path="/courses"
              element={
                <PrivateRoute>
                  <CoursesList />
                </PrivateRoute>
              }
            />
            <Route
              path="/courses/new"
              element={
                <PrivateRoute>
                  <CourseEditor />
                </PrivateRoute>
              }
            />
            <Route
              path="/courses/edit/:id"
              element={
                <PrivateRoute>
                  <CourseEditor />
                </PrivateRoute>
              }
            />
            <Route path="*" element={<Navigate to="/" replace />} />
          </Routes>
        </Container>
      </Router>
    </AuthProvider>
  );
}

export default App;
