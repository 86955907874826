import React, { useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import QuillEditor from './QuillEditor';
import ImageUploader from './ImageUploader';

const CourseElementModal = ({ show, onHide, onSave, type, courseId }) => {
  const [content, setContent] = useState('');

  const handleSave = () => {
    onSave(type, content);
    setContent('');
  };

  return (
    <Modal show={show} onHide={onHide} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>{type === 'html' ? 'Add HTML Content' : 'Add Image'}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {type === 'html' ? (
          <QuillEditor value={content} onChange={setContent} />
        ) : (
          <ImageUploader value={content} onChange={setContent} courseId={courseId} />
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>Close</Button>
        <Button variant="primary" onClick={handleSave}>Save Changes</Button>
      </Modal.Footer>
    </Modal>
  );
};

export default CourseElementModal;
